import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

const ListItemContainer = styled.div.attrs(() => ({
  className: 'ListItemContainer',
}))``;

const Image = styled.img.attrs(() => ({
  className: 'Image',
}))`
  width: 100%;
`;

const DescriptionWrap = styled.div`
  p, strong {
    font-family: 'Proxima Nova';
  }
  ${({ alignment }) => `
    text-align: ${alignment};
  `}
`;

const ListItem = ({
  title,
  description,
  image,
  textAlign = 'center',
  className,
  delayAnimation,
  durationAnimation,
}) => (
  <ListItemContainer className={`wow ${className}`} data-wow-duration={durationAnimation} data-wow-delay={delayAnimation}>
    <Image alt={title} src={image} />
    <DescriptionWrap alignment={textAlign}>
      <ReactMarkdown>
        {description}
      </ReactMarkdown>
    </DescriptionWrap>
  </ListItemContainer>
);

// eslint-disable-next-line react/no-typos
ListItem.propTypes = {
  description: PropTypes.string.isRequired,
  delayAnimation: PropTypes.string,
  durationAnimation: PropTypes.string,
};

ListItem.defaultProps = {
  delayAnimation: '1s',
  durationAnimation: '1s',
};

export default ListItem;
